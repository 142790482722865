exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-activation-js": () => import("./../../../src/pages/activation.js" /* webpackChunkName: "component---src-pages-activation-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-success-freespin-js": () => import("./../../../src/pages/success-freespin.js" /* webpackChunkName: "component---src-pages-success-freespin-js" */),
  "component---src-pages-success-full-js": () => import("./../../../src/pages/success-full.js" /* webpackChunkName: "component---src-pages-success-full-js" */),
  "component---src-pages-world-cup-2022-js": () => import("./../../../src/pages/world-cup-2022.js" /* webpackChunkName: "component---src-pages-world-cup-2022-js" */),
  "component---src-templates-category-loadmore-js": () => import("./../../../src/templates/category/loadmore.js" /* webpackChunkName: "component---src-templates-category-loadmore-js" */),
  "component---src-templates-category-promotion-js": () => import("./../../../src/templates/category/promotion.js" /* webpackChunkName: "component---src-templates-category-promotion-js" */),
  "component---src-templates-category-regular-js": () => import("./../../../src/templates/category/regular.js" /* webpackChunkName: "component---src-templates-category-regular-js" */),
  "component---src-templates-category-search-js": () => import("./../../../src/templates/category/search.js" /* webpackChunkName: "component---src-templates-category-search-js" */),
  "component---src-templates-games-fish-shooting-game-js": () => import("./../../../src/templates/games/fish-shooting-game.js" /* webpackChunkName: "component---src-templates-games-fish-shooting-game-js" */),
  "component---src-templates-games-game-js": () => import("./../../../src/templates/games/game.js" /* webpackChunkName: "component---src-templates-games-game-js" */),
  "component---src-templates-page-about-js": () => import("./../../../src/templates/page/about.js" /* webpackChunkName: "component---src-templates-page-about-js" */),
  "component---src-templates-page-baccarat-js": () => import("./../../../src/templates/page/baccarat.js" /* webpackChunkName: "component---src-templates-page-baccarat-js" */),
  "component---src-templates-page-banking-js": () => import("./../../../src/templates/page/banking.js" /* webpackChunkName: "component---src-templates-page-banking-js" */),
  "component---src-templates-page-bk-8-js": () => import("./../../../src/templates/page/bk8.js" /* webpackChunkName: "component---src-templates-page-bk-8-js" */),
  "component---src-templates-page-bwin-js": () => import("./../../../src/templates/page/bwin.js" /* webpackChunkName: "component---src-templates-page-bwin-js" */),
  "component---src-templates-page-casino-js": () => import("./../../../src/templates/page/casino.js" /* webpackChunkName: "component---src-templates-page-casino-js" */),
  "component---src-templates-page-casino-provider-js": () => import("./../../../src/templates/page/casino_provider.js" /* webpackChunkName: "component---src-templates-page-casino-provider-js" */),
  "component---src-templates-page-claim-promo-form-js": () => import("./../../../src/templates/page/claim_promo_form.js" /* webpackChunkName: "component---src-templates-page-claim-promo-form-js" */),
  "component---src-templates-page-download-js": () => import("./../../../src/templates/page/download.js" /* webpackChunkName: "component---src-templates-page-download-js" */),
  "component---src-templates-page-gclub-login-js": () => import("./../../../src/templates/page/gclub_login.js" /* webpackChunkName: "component---src-templates-page-gclub-login-js" */),
  "component---src-templates-page-lotto-js": () => import("./../../../src/templates/page/lotto.js" /* webpackChunkName: "component---src-templates-page-lotto-js" */),
  "component---src-templates-page-provider-subbrand-js": () => import("./../../../src/templates/page/provider_subbrand.js" /* webpackChunkName: "component---src-templates-page-provider-subbrand-js" */),
  "component---src-templates-page-register-js": () => import("./../../../src/templates/page/register.js" /* webpackChunkName: "component---src-templates-page-register-js" */),
  "component---src-templates-page-regular-js": () => import("./../../../src/templates/page/regular.js" /* webpackChunkName: "component---src-templates-page-regular-js" */),
  "component---src-templates-page-slot-js": () => import("./../../../src/templates/page/slot.js" /* webpackChunkName: "component---src-templates-page-slot-js" */),
  "component---src-templates-page-survey-dpvd-js": () => import("./../../../src/templates/page/survey_dpvd.js" /* webpackChunkName: "component---src-templates-page-survey-dpvd-js" */),
  "component---src-templates-page-survey-js": () => import("./../../../src/templates/page/survey.js" /* webpackChunkName: "component---src-templates-page-survey-js" */),
  "component---src-templates-page-survey-register-js": () => import("./../../../src/templates/page/survey_register.js" /* webpackChunkName: "component---src-templates-page-survey-register-js" */),
  "component---src-templates-page-survey-service-js": () => import("./../../../src/templates/page/survey_service.js" /* webpackChunkName: "component---src-templates-page-survey-service-js" */),
  "component---src-templates-page-survey-vip-js": () => import("./../../../src/templates/page/survey_vip.js" /* webpackChunkName: "component---src-templates-page-survey-vip-js" */),
  "component---src-templates-page-with-provider-carousel-js": () => import("./../../../src/templates/page/with_provider_carousel.js" /* webpackChunkName: "component---src-templates-page-with-provider-carousel-js" */),
  "component---src-templates-post-regular-js": () => import("./../../../src/templates/post/regular.js" /* webpackChunkName: "component---src-templates-post-regular-js" */),
  "component---src-templates-types-tag-js": () => import("./../../../src/templates/types/tag.js" /* webpackChunkName: "component---src-templates-types-tag-js" */)
}

