function initGTM() {
  if (window?.isGTMLoaded) {
    return false;
  }
  window.isGTMLoaded = true;
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.defer = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GTM}`;
  script.onload = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', `${process.env.GATSBY_GTM}`);
  };
  document.head.appendChild(script);
}

function loadGTM(event) {
  initGTM();
  event.currentTarget.removeEventListener(event.type, loadGTM);
}
exports.onClientEntry = () => {
  document.onreadystatechange = () => {
    if (document.readyState !== 'loading') {
      setTimeout(initGTM, 3500);
    }
  };

  document.addEventListener('scroll', loadGTM);
  document.addEventListener('mousemove', loadGTM);
  document.addEventListener('touchstart', loadGTM);
};
